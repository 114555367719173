<template>
  <div v-if="card.id">
    <input class="hidden checked-card" type="radio" name="visa" :id="card.id" />
    <label :for="card.id" class="flex justify-between items-center border-solid border-2 border-sky-500 w-full p-5 font-bold">
      <div>
        <div class="flex items-center gap-5">
          <div class="p-3 rounded-full border-solid border-2 border-sky-500 check-card">
          </div>
          <div class="flex gap-1 points">
            <span class="h-1 w-1 bg-gray-500 rounded-full"></span>
            <span class="h-1 w-1 bg-gray-500 rounded-full"></span>
            <span class="h-1 w-1 bg-gray-500 rounded-full"></span>
            <span class="h-1 w-1 bg-gray-500 rounded-full"></span>
          </div>
          <span>{{ card.numberCard }}</span>
        </div>
        <div class="text-gray-400 ml-8">
          <button class="font-bold">{{ card.type }}</button> <span class="font-bold separator">|</span> <button
            class="font-bold">Edit</button>
        </div>
      </div>
      <div>
        <img class="h-7" :src="image" :alt="card.type"/>
      </div>
    </label>
  </div>
  <div v-else>
    <label for="visa" class="flex justify-center border-solid bg-light-blue-card cursor-pointer w-full h-full p-5">
      <img class="w-10" src="@/assets/icons/add-payment-icon.svg" alt="Add payment icon">
    </label>
  </div>
</template>

<script setup>
import { computed } from 'vue';
const props = defineProps(['card']);
const image =  computed(() => require(`@/assets/icons/${props.card?.logo}`));
</script>
<style lang="scss" scoped>
.checked-card:checked+label {
  border: 2px solid #1D2E48;
  position: relative;

  .check-card {
    background-color: #1D2E48;
  }

  .points {
    span {
      background-color: #1D2E48;
    }
  }

  .separator {
    color: #1D2E48;
  }

  &::after {
    content: "";
    height: 14px;
    width: 14px;
    background-color: white;
    display: block;
    top: 27px;
    left: 26.5px;
    border-radius: 50%;
    position: absolute;
  }

}
</style>