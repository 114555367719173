const columns = [
  {
    header: 'Date',
    name: 'date',
    accessor: 'date',
  },
  {
    header: 'Detail',
    name: 'detail',
    accessor: 'detail',
  },
  {
    header: 'Amount',
    name: 'amount',
    accessor: 'amount',
  },
  {
    header: 'Download',
    name: 'download',
    accessor: 'download',
  }
]

let data = [
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  }, 
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  }, 
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  }, 
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  }, 
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  }, 
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  }, 
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  }, 
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  }, 
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  }, 
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },{
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  }, 
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  }, 
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  }, 
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },{
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  }, 
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  }, 
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  },
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  }, 
  {
    id: 1,
    date: "26/09/2002",
    detail: "Profesional plan, montly",
    amount: "$9.90",
    download: "26-09-22"
  }
]

export {
  columns,
  data
}