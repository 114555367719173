<template>
  <section class="bg-white w-full h-full mb-5">
    <alt-table :className="!rows ? ['d-table'] : ['']" :columns="columns"
      @onPagination="handlePagination" pagination :paginationOptions="paginationOptions">
      <template #t-row="{ accessors }">
        <tr class="custom-tr" v-for="(row, index) in rows" :key="index">
          <td class="custom-th" v-for="accessor in accessors" :key="accessor">
            <span>{{ row[accessor] || ' ' }}</span>
          </td>
        </tr>
      </template>
    </alt-table>
  </section>
</template>

<script setup>
import { reactive, ref } from "vue";
import altTable from "@/components/tables/altTable.vue";
import { columns, data, pagination } from "@/components/manage/subscription/utils"

const paginationOptions = reactive({ currentPage: 1, total: 0 });
const rows = ref([]);
function handlePagination({ currentPage }) {
  paginationOptions.total = data.length;
  rows.value = pagination(data, 20, currentPage);
}
handlePagination({currentPage: 1});
</script>
