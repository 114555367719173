const cards = [
  {
    id: 1,
    numberCard: 3132,
    type: "Visa",
    logo: "visa-icon.png"
  },
  {
    id: 2,
    numberCard: 8745,
    type: "Mastercard",
    logo: "mastercard-icon.png"
  },
  {
    id: 3,
    numberCard: 3659,
    type: "Visa",
    logo: "visa-icon.png"
  },
  {
    id: 4,
    numberCard: 2455,
    type: "Mastercard",
    logo: "mastercard-icon.png"
  },
  {
    logo: ''
  }
]

export {
  cards
}