<template>
  <div class="wrapper-table" :class="[className, type]">
    <table class="min-w-full leading-normal">
      <thead>
        <tr>
          <th v-for="(column, index) in columns" :key="index" class="column-table">
            <span class="th-content">{{ column.header }}</span>
          </th>
        </tr>
      </thead>
      <tbody class="t-body">
        <slot name="t-row" :accessors="accessor" />
      </tbody>
    </table>
    <Pagination v-if="pagination" :pagination="paginationOptions" @onChangePagination="handleChangePagination" />
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import Pagination from './Pagination'
export default {
  components: { Pagination },
  props: {
    columns: {
      type: Array,
      required: true
    },
    rows: {
      type: Array
    },
    className: {
      type: Array
    },
    type: {
      type: String,
      default: 'default'
    },
    pagination: {
      type: Boolean,
      default: false
    },
    paginationOptions: {
      type: Object,
      default() {
        return {
          total: 30,
          currentPage: 1
        }
      }
    }
  },
  setup(props, ctx) {

    const accessor = computed(() => {
      return props.columns.map((column) => column.accessor) || []
    })

    function handleChangePagination(params) {
      ctx.emit('onPagination', params);
    }
    return {
      accessor,
      handleChangePagination
    }
  }
}
</script>
<style lang="scss">
.wrapper-table {
  border-radius: .5rem;
  display: block;
  min-width: 100%;
  overflow: auto;

  table {
    min-width: 100%;
    line-height: 1.5;
    border-collapse: collapse;

    &.pagination-table {
      display: block;
      overflow-x: auto;
    }

    .column-table {
      border-bottom-width: 2px;
      padding: .7rem 1.25rem;
      text-align: left;
      font-weight: 400;
      line-height: 1rem;
      background-color: #ECF0F5;
      color: #1D2E48;
      font-weight: bold;

      span {
        color: black;
      }

      .th-content {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        justify-content: space-between;
      }
    }

    .t-body td {
      font-size: .875rem;
      line-height: 1;
      padding: 8px;
      border-bottom-width: 1px;
      width: 40%;
      font-weight: 500;
    }

    .filter-column {
      background-color: whitesmoke;
      position: sticky;
      top: 43px;
      z-index: 2;
    }

    .filter-input {
      width: 100%;
      font-size: 1rem;
      border: solid 1px darkgray;
      padding: .3rem;
      border-radius: 3px;

      &::placeholder {
        font-size: 10pt;
      }
    }

    .sort-icon {
      display: inline-block;
      cursor: pointer;

      img {
        width: 20px;
      }
    }
  }

  tr {
    &:nth-child(2n+1) {
      background: #F7F9FB;
    }
  }
}
</style>
