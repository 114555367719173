<template>
  <div class="pagination">
    <div class="cursor-pointer" @click="previousPage" v-if="!isPageDisable">
      <img class="h-5" src="@/assets/icons/arrow-left-icon.png" alt="Icon arrow left">
    </div>
    <div class="flex justify-center pagination-child">
      <p>{{ currentPage }} of {{ getCountPage }} Page</p>
    </div>
    <div class="cursor-pointer" @click="nextPage" v-if="!isLastPage">
      <img class="h-5" src="@/assets/icons/arrow-rigth-icon.png" alt="Icon arrow rigth"/>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
export default {
  props: {
    pagination: {
      type: Object,
      default: {
        total: 30,
        currentPage: 1,
      },
    },
  },
  setup(props, ctx) {
    const currentPage = ref(props.pagination.current || 1);
    const getCountPage = computed(() => Math.ceil(props.pagination.total / 20));
    const isPageDisable = computed(() => Number(currentPage.value) === 1);
    const isLastPage = computed(() => getCountPage.value === currentPage.value);

    watch([currentPage], () => {
      const values = {
        currentPage: currentPage.value
      };
      ctx.emit("onChangePagination", { ...values });
    });

    const nextPage = () => {
      if(isLastPage.value) return;
      currentPage.value+= 1;
    }

    const previousPage = () => {
      if(currentPage.value <= 1) return;
      currentPage.value-= 1;
    }

    return {
      currentPage,
      nextPage,
      previousPage,
      isPageDisable,
      isLastPage,
      getCountPage
    };
  },
};
</script>

<style lang="scss" scoped>
.select-table {
  padding: 3px 1rem;
  border: none;
  border-bottom: solid;
  border-radius: 5px;
  border-color: $light-grey;
  outline: none;
}

.pagination {
  padding: 1rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid;
  font-size: 10pt;
  gap: 1rem;
  font-weight: 600;

  @media (max-width: 520px) {
    &-child {
      justify-content: center;
      width: 100%;
      padding: 0.3rem 0;
      border-color: $light-grey;
    }

    &-label {
      display: none;
    }

    .select-table {
      width: 100%;
    }
  }
}
</style>
